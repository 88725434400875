// modifer: styles/jss/nextjs-material-kit-pro/pages/componentsSections/footerStyle
import { whiteColor } from 'styles/jss/original/base';

const style = {
  footerWrapper: {
    marginTop: 'auto'
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  listItem: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
    '@media (max-width: 360px)': {
      width: '50%',
      textAlign: 'center'
    },
    '& a': {
      display: 'inline-block'
    }
  },
  logo: {
    float: 'left',
    display: 'block',
    '@media (max-width: 640px)': {
      float: 'none'
    }
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
    '@media (max-width: 411px)': {
      padding: '0.625rem'
    }
  },
  footerBrand: {
    fontSize: '1rem',
    margin: '0',
    height: '50px',
    padding: '15px 15px',
    lineHeight: '50px',
    marginLeft: '-15px',
    '& .icon-logo:before': {
      color: whiteColor
    },
    '@media (max-width: 640px)': {
      fontSize: '0.9375rem'
    }
  },
  menu: {
    display: 'inline-block',
    float: 'none'
  },
  snsIcon: {
    float: 'right',
    '& ul': {
      marginBottom: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'inline-block'
      },
      '& a': {
        display: 'block'
      }
    },
    '& i': {
      fontSize: '20px'
    },
    '@media (max-width: 640px)': {
      float: 'none'
    }
  }
};

export default style;
