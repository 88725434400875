import { User } from '@/domains/firestore/user';

const KEY = 'userinformation';

export async function isStorageAvailable(type: string) {
  let storage: Window['localStorage'];
  try {
    // @ts-expect-error index 式が 'number' 型でないため、Element は暗黙のうちに 'any' 型を持つ。
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export async function save(userInformation: User) {
  await localStorage.setItem(KEY, JSON.stringify(userInformation));
}

export async function retrieve() {
  const serialized = await localStorage.getItem(KEY);
  if (!serialized) {
    return null;
  }
  return JSON.parse(serialized) as User;
}

export async function clear() {
  await localStorage.removeItem(KEY);
}
