import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import styles from 'styles/jss/original/components/common/header';
import classNames from 'classnames';

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  pathname?: string;
  color?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'transparent'
    | 'white'
    | 'rose'
    | 'dark';
  links: React.ReactNode;
  brand: string;
  fixed?: boolean;
  absolute?: boolean;
  changeColorOnScroll?: {
    height: number;
    color:
      | 'primary'
      | 'info'
      | 'success'
      | 'warning'
      | 'danger'
      | 'transparent'
      | 'white'
      | 'rose'
      | 'dark';
  };
};

const Header: React.FC<ComponentProps> = ({
  pathname = '',
  color = 'white',
  links,
  brand,
  fixed = false,
  absolute = false,
  changeColorOnScroll
}) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    if (!changeColorOnScroll) return;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {pathname === '/' ? (
          <h1 className={classes.brand}>
            <Button className={classes.title}>
              <Link href='/'>
                <a>
                  <em className={classes.name}>{brand}</em>
                  <span className='icon-logo' />
                </a>
              </Link>
            </Button>
          </h1>
        ) : (
          <Button className={classes.title}>
            <Link href='/'>
              <a>
                <em className={classes.name}>{brand}</em>
                <span className={classNames('icon-logo', 'header')} />
              </a>
            </Link>
          </Button>
        )}
        {/* @ts-expect-error タイプ '{ children: Element; smDown: true; implementation: "css"; className: string; }' は、タイプ 'IntrinsicAttributes & HiddenProps & { children? ReactNode; }』となります。 */}
        <Hidden smDown implementation='css' className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export default Header;
