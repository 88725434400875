import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import styles from 'styles/jss/original/components/common/navbar';
import classNames from 'classnames';
import Header from '@/components/common/Header';

type ComponentProps = {
  pathname?: string;
  onRequestLogout: () => void;
  isMember: boolean;
};

// @ts-expect-error
const useStyles = makeStyles(styles);

const Headroom = dynamic(() => import('react-headroom'));

const Navbar: React.FC<ComponentProps> = ({ pathname, onRequestLogout, isMember }) => {
  const classes = useStyles();
  const navbarList = useMemo(
    () => [
      {
        link: '/menus',
        title: '献立',
        isButton: false
      },
      {
        link: '/ideas',
        title: 'アイデア',
        isButton: false
      },
      {
        link: '/shoppings',
        title: 'ショッピング',
        isButton: false
      },
      {
        link: '/favorites',
        title: 'お気に入り',
        isButton: false
      },
      {
        link: '/logins',
        title: isMember ? 'ログアウト' : 'ログイン',
        isButton: true
      }
    ],
    [isMember]
  );

  return (
    <div>
      {/* @ts-expect-error タイプ '"100"' はタイプ 'ZIndex | undefined' に割り当てられません。 */}
      <Headroom style={{ zIndex: '100' }}>
        <Header
          brand='smalldish'
          color='white'
          pathname={pathname}
          links={
            <List className={classNames(classes.list, classes.mlAuto)}>
              {navbarList.map((item) => {
                return item.isButton ? (
                  <ListItem className={classes.listItem} key={item.title}>
                    {!isMember ? (
                      <Link href={item.link}>
                        <a className={classes.colorWhite}>
                          {' '}
                          {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                          <Button color={'warning'} className={classes.navButton} round>
                            {item.title}
                          </Button>
                        </a>
                      </Link>
                    ) : (
                      // @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。
                      <Button className={classes.navButton} round onClick={onRequestLogout}>
                        {item.title}
                      </Button>
                    )}
                  </ListItem>
                ) : (
                  <ListItem className={classes.listItem} key={item.title}>
                    <Link href={item.link}>
                      <a className={classes.colorGray}>
                        {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                        <Button className={classes.navLink} color='transparent'>
                          {item.title}
                        </Button>
                      </a>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          }
        />
      </Headroom>
    </div>
  );
};

export default Navbar;
