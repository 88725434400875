import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Footer from '@/components/material-ui-kit/components/Footer/Footer';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import styles from 'styles/jss/original/components/common/footer';
import classNames from 'classnames';

// @ts-expect-error
const useStyles = makeStyles(styles);

const menuList = [
  {
    link: '/about',
    title: 'メディア紹介'
  },
  {
    link: '/terms',
    title: '利用規約'
  },
  {
    link: '/privacies',
    title: 'プライバシー'
  },
  {
    link: '/contacts',
    title: 'お問い合わせ'
  }
];

const snsIconList = [
  {
    link: 'https://twitter.com/smalldish_jp',
    type: 'twitter'
  },
  {
    link: 'https://www.facebook.com/smalldish.jp',
    type: 'facebook'
  },
  {
    link: 'https://www.instagram.com/smalldish.jp/',
    type: 'instagram'
  }
];

const Footers: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerWrapper}>
      <Footer
        theme='dark'
        content={
          <div>
            <div className={classes.logo}>
              <Link href='/'>
                <a className={classes.footerBrand}>
                  <span className='icon-logo' />
                </a>
              </Link>
            </div>
            <div className={classes.menu}>
              <List className={classes.list}>
                {menuList.map((item) => {
                  return (
                    <ListItem className={classes.listItem} key={item.title}>
                      <Link href={item.link}>
                        <a className={classes.block}>{item.title}</a>
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <div className={classes.snsIcon}>
              <ul>
                {snsIconList.map((item) => {
                  return (
                    <li key={item.type}>
                      {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                      <Button href={item.link} target='_blank' color='white' justIcon simple>
                        <i className={classNames('fab', `fa-${item.type}`)} />
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Footers;
