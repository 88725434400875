import React, { useCallback } from 'react';
import ModalLogin from '@/components/common/ModalLogin';
import { useUiStatus } from '@/hooks/useUiStatus';
import { callAuth } from '@/lib/firebase/auth';

const ModalLoginContainer: React.FC = () => {
  const { modalState, setModalState } = useUiStatus();
  const handleOnRequestAuth = useCallback(async (snsType: string) => {
    await callAuth(snsType);
  }, []);
  const handleOnRequestClose = useCallback(() => {
    setModalState(false);
  }, [setModalState]);

  return (
    <>
      {modalState ? (
        <ModalLogin
          onRequestAuth={handleOnRequestAuth}
          onRequestClose={handleOnRequestClose}
          isOpenModal={modalState}
        />
      ) : null}
    </>
  );
};

export default ModalLoginContainer;
