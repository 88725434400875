import {
  SET_CREDENTIAL_USER,
  RESET_CREDENTIAL_USER,
  UPDATE_USER,
  SET_CURRENT_USER
} from '@/actions/actionTypes';
import { setUser, getUser, FirebaseUser, User } from '@/domains/firestore/user';
import { outAuth } from '@/lib/firebase/auth';
import * as userInformation from '@/domains/local-store/userInformation';
import { AppDispatch } from '@/store/configureStore';

/**
 * Action Creator
 */
export const innerSetCredentialUser = (user: User) => ({
  type: SET_CREDENTIAL_USER,
  payload: {
    user
  }
});

export const innerResetCredentialUser = () => ({
  type: RESET_CREDENTIAL_USER
});

export const innerUpdateUser = (user: User) => ({
  type: UPDATE_USER,
  payload: {
    user
  }
});

export const innerSetCurrentUser = (user: User) => ({
  type: SET_CURRENT_USER,
  payload: {
    user
  }
});

/**
 * Action
 */
export const setCredentialUser = (firebaseUser: FirebaseUser) => async (dispatch: AppDispatch) => {
  await setUser(firebaseUser).then(async (tempUser) => {
    const user = (await getUser(tempUser.uid)) || tempUser;
    const isAvailable = await userInformation.isStorageAvailable('localStorage');
    if (isAvailable) await userInformation.save(user);
    dispatch(innerSetCredentialUser(user));
  });
};

export const resetCredentialUser = () => async (dispatch: AppDispatch) => {
  await outAuth();
  const isAvailable = await userInformation.isStorageAvailable('localStorage');
  if (isAvailable) await userInformation.clear();
  dispatch(innerResetCredentialUser());
};

export const updateUser = (uid: string) => async (dispatch: AppDispatch) => {
  const user = await getUser(uid);
  if (!user) return;
  const isAvailable = await userInformation.isStorageAvailable('localStorage');
  if (isAvailable) await userInformation.save(user);
  dispatch(innerUpdateUser(user));
};

export const setCurrentUser = (user: User) => async (dispatch: AppDispatch) => {
  dispatch(innerSetCurrentUser(user));
};
