// modifer: styles/jss/nextjs-material-kit-pro/pages/aboutUsStyle.js
const styles = {
  layout: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  body: {
    overflow: 'hidden'
  },
  page: {
    position: 'fixed',
    zIndex: '9999',
    width: '100%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  content: {
    width: '100%',
    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: "''",
      display: 'block',
      zIndex: '9999',
      position: 'absolute',
      background: 'rgba(255, 255, 255, 0.75)'
    }
  }
};

export default styles;
