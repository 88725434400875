import { getUserFirestore } from '@/lib/firebase/firestore';

export type FirebaseUser = {
  uid: string;
  displayName: string;
  email: string;
  photoURL: string;
};

export type User = {
  uid: string;
  name: Todo;
  img: Todo;
  favorited: string[];
  liked: string[];
};

export async function setUser(firebaseUser: FirebaseUser) {
  const userId = firebaseUser.uid;
  getUserFirestore()
    .doc(userId)
    .set({
      uid: firebaseUser.uid,
      name: firebaseUser.displayName,
      email: firebaseUser.email.replace('@', '_at_').replace(/\./g, '_dot_'),
      img: firebaseUser.photoURL
    });
  return {
    uid: firebaseUser.uid,
    name: firebaseUser.displayName,
    img: firebaseUser.photoURL,
    favorited: [],
    liked: []
  };
}

export async function getUser(userId: string) {
  try {
    const user = await getUserFirestore()
      .doc(userId)
      .get()
      .then((res) => res.data());
    if (!user) return;
    const favorited: string[] = [];
    const liked: string[] = [];
    const querySnapshotFavorited = await getUserFirestore()
      .doc(`${userId}`)
      .collection('favorited')
      .get();
    querySnapshotFavorited.docs.map((doc) => favorited.push(doc.id));
    const querySnapshotLiked = await getUserFirestore()
      .doc(`${userId}`)
      .collection('liked')
      .get();
    querySnapshotLiked.docs.map((doc) => liked.push(doc.id));
    return {
      uid: userId,
      name: user.name,
      img: user.img,
      favorited,
      liked
    };
  } catch (error) {
    throw new Error();
  }
}

export async function favoritePost({ userId, postId }: { userId: string; postId: string }) {
  try {
    await getUserFirestore()
      .doc(`${userId}`)
      .collection('favorited')
      .doc(`${postId}`)
      .get()
      .then(async (doc) => {
        if (!doc.exists) {
          getUserFirestore()
            .doc(`${userId}`)
            .collection('favorited')
            .doc(`${postId}`)
            .set({ timestamp: Date.now() });
        } else {
          getUserFirestore()
            .doc(`${userId}`)
            .collection('favorited')
            .doc(`${postId}`)
            .delete();
        }
      });
  } catch (error) {
    throw new Error();
  }
}

export async function likeCommentShopping({
  commentId,
  userId
}: {
  commentId: string;
  userId: string;
}) {
  try {
    let liked = true;
    await getUserFirestore()
      .doc(`${userId}`)
      .collection('liked')
      .doc(`${commentId}`)
      .get()
      .then(async (doc) => {
        if (!doc.exists) {
          getUserFirestore()
            .doc(`${userId}`)
            .collection('liked')
            .doc(`${commentId}`)
            .set({ timestamp: Date.now() });
          liked = true;
        } else {
          getUserFirestore()
            .doc(`${userId}`)
            .collection('liked')
            .doc(`${commentId}`)
            .delete();
          liked = false;
        }
      });
    return liked;
  } catch (error) {
    throw new Error();
  }
}
