import React, { useMemo, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Card from '@/components/material-ui-kit/components/Card/Card';
import styles from 'styles/jss/original/components/common/modalLogin';
import classNames from 'classnames';

type ComponentProps = {
  onRequestAuth: (sns: string) => void;
  onRequestClose: () => void;
  isOpenModal: boolean;
};

const modalText = {
  title: 'ログイン',
  description: 'お手持ちのSNSアカウントを使ってログインください',
  button: '閉じる'
};

// @ts-expect-error
const useStyles = makeStyles(styles);

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-expect-error タイプ '{ children? ReactNode; direction: "down"; ref: ForwardedRef<unknown>; }' は 'SlideProps' タイプには割り当てられません
  return <Slide direction='down' ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const ModalLogin: React.FC<ComponentProps> = ({ onRequestAuth, onRequestClose, isOpenModal }) => {
  const classes = useStyles();
  const buttonList = useMemo(
    () => [
      {
        color: 'facebook',
        icon: 'facebook-square',
        title: ' Sign in with Facebook',
        onClick: () => onRequestAuth('facebook')
      },
      {
        color: 'twitter',
        icon: 'twitter',
        title: ' Sign in with Twitter',
        onClick: () => onRequestAuth('twitter')
      },
      {
        color: 'google',
        icon: 'google-plus-square',
        title: ' Sign in with Google',
        onClick: () => onRequestAuth('google')
      }
    ],
    [onRequestAuth]
  );

  return (
    <GridContainer>
      {/* @ts-expect-error */}
      <GridItem xs={12} sm={6} md={4} lg={4}>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={isOpenModal}
          // @ts-expect-error
          TransitionComponent={Transition}
          keepMounted
          onClose={onRequestClose}
          aria-labelledby='signin-modal-slide-title'
          aria-describedby='signin-modal-slide-description'
        >
          <Card plain className={classes.modalSignupCard}>
            <DialogTitle
              id='signin-modal-slide-title'
              disableTypography
              className={classes.modalHeader}
            >
              {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
              <Button
                simple
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                onClick={onRequestClose}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h3 className={classes.cardTitle}>{modalText.title}</h3>
              <p className={classes.description}>{modalText.description}</p>
            </DialogTitle>
            <DialogContent id='signin-modal-slide-description' className={classes.modalBody}>
              <GridContainer>
                {/* @ts-expect-error */}
                <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
                  <div className={classes.textCenter}>
                    {buttonList.map((item) => {
                      return (
                        <div key={item.icon}>
                          {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                          <Button
                            color={item.color}
                            className={classes.snsButton}
                            onClick={item.onClick}
                          >
                            <i className={classNames('fab', `fa-${item.icon}`)} />
                            {item.title}
                          </Button>
                          <br />
                        </div>
                      );
                    })}
                    {/* @ts-expect-error プロパティ 'children' は、'IntrinsicAttributes & RefAttributes<any>' 型には存在しません。*/}
                    <Button
                      simple
                      className={classNames(classes.description, classes.closeButton)}
                      key='close'
                      aria-label='Close'
                      onClick={onRequestClose}
                    >
                      {modalText.button}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </DialogContent>
          </Card>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
};

export default ModalLogin;
