import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ModalLoginContainer from '@/containers/common/ModalLoginContainer';
import NavbarContainer from '@/containers/common/NavbarContainer';
import Footer from '@/components/common/Footer';
import PageChange from '@/components/common/PageChange';
import { setCredentialUser, setCurrentUser } from '@/actions/user';
import { FirebaseUser } from '@/domains/firestore/user';
import useIsWebview from '@/hooks/useIsWebview';
import useUser from '@/hooks/useUser';
import useScrollPositionSp from '@/hooks/useScrollPositionSp';
import styles from 'styles/jss/original/layouts/main';
import { UiContext } from '@/contexts/ui';
import { retrieve } from '@/domains/local-store/userInformation';
import { initialiseFirebaseAuthentication } from '@/lib/firebase/auth';

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  children: React.ReactNode;
  pathname?: string;
};

const Main: React.FC<ComponentProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const isWebView = useIsWebview();
  const user = useUser();

  useEffect(() => {
    const fn = async () => {
      if (!user) {
        const userInformation = await retrieve();
        if (!userInformation) {
          setLoading(true);
          const firebaseUser = (await initialiseFirebaseAuthentication()) as FirebaseUser;
          if (!firebaseUser) {
            setLoading(false);
            return;
          }
          await dispatch(setCredentialUser(firebaseUser));
          setLoading(false);
        } else {
          setLoading(true);
          await dispatch(setCurrentUser(userInformation));
          setLoading(false);
        }
      }
    };
    fn();
  }, [dispatch, user]);
  useScrollPositionSp();
  const { children, pathname } = props;
  return (
    <UiContext.Provider value={{ modalState, setModalState }}>
      {loading ? (
        <div className={classes.body}>
          <div className={classes.page}>
            <div className={classes.content}>
              {isWebView ? null : <NavbarContainer pathname={pathname} />}
              {children}
              {isWebView ? null : <Footer />}
              <PageChange />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.layout}>
          {isWebView ? null : <NavbarContainer pathname={pathname} />}
          {children}
          {isWebView ? null : <Footer />}
          <ModalLoginContainer />
        </div>
      )}
    </UiContext.Provider>
  );
};

export default Main;
