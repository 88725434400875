import { useState } from 'react';
import { useMount } from 'react-use';

const WEB_VIEW = 'ReactNativeWebView';

export default function useIsWebview() {
  const [isWebView, setWebView] = useState(false);
  useMount(() => {
    if (WEB_VIEW in window) {
      setWebView(true);
    }
  });
  return isWebView;
}
