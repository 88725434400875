import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetCredentialUser } from '@/actions/user';
import Navbar from '@/components/common/Navbar';
import useUser from '@/hooks/useUser';

type ComponentProps = {
  pathname?: string;
};

const NavbarContainer: React.FC<ComponentProps> = ({ pathname }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const handleRequestLogout = useCallback(() => {
    dispatch(resetCredentialUser());
  }, [dispatch]);

  return <Navbar pathname={pathname} onRequestLogout={handleRequestLogout} isMember={!!user} />;
};

export default NavbarContainer;
