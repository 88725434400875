import { useEffect } from 'react';
import { useMedia } from 'react-use';
import _ from 'lodash';

export default function useScrollPositionSp() {
  const isSp = useMedia('(max-width:640px)');

  useEffect(() => {
    if (isSp) {
      _.defer(() => window.scrollTo(0, 0));
    }
  }, [isSp]);
}
