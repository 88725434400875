// modifer: styles/jss/nextjs-material-kit-pro/pages/componentsSections/javascriptStyles.js
import { container, title, cardTitle, description, mlAuto, mrAuto } from 'styles/jss/original/base';

import modalStyle from 'styles/jss/nextjs-material-kit-pro/modalStyle.js';

const styles = (theme) => ({
  container,
  description: {
    ...description,
    textAlign: 'center',
    margin: '0'
  },
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
    marginLeft: '50px',
    '@media (max-width: 640px)': {
      marginLeft: '0'
    }
  },
  mlAuto,
  mrAuto,
  ...modalStyle(theme),
  title: {
    ...title,
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none'
  },
  textCenter: {
    textAlign: 'center'
  },
  closeButton: {
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent'
    }
  },
  snsButton: {
    minWidth: '200px'
  },
  modalSignupCard: {
    margin: '10px 0'
  }
});

export default styles;
