import firebase from '@/lib/firebase/initFirebase';
import 'firebase/auth';

export function initialiseFirebaseAuthentication() {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      let tempUser;
      if (!user) {
        tempUser = firebase.auth().currentUser || null;
      } else {
        tempUser = user;
      }
      resolve(tempUser);
    });
  });
}

export function callAuth(snsType: string) {
  switch (snsType) {
    case 'google':
      firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
      break;
    case 'facebook':
      firebase.auth().signInWithRedirect(new firebase.auth.FacebookAuthProvider());
      break;
    case 'twitter':
      firebase.auth().signInWithRedirect(new firebase.auth.TwitterAuthProvider());
      break;
  }
}

export function checkAuth() {
  firebase
    .auth()
    .getRedirectResult()
    .then((result) => {
      if (result.user || firebase.auth().currentUser) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function(error) {
      throw new Error(error);
    });
}

export function outAuth() {
  firebase.auth().signOut();
}
