import { createContext } from 'react';

type UiContextType = {
  modalState: boolean;
  setModalState: (status: boolean) => void;
};

export const UiContext = createContext<UiContextType>({
  modalState: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModalState: () => {}
});
