// modifer: styles/jss/nextjs-material-kit-pro/pages/sectionsSections/headersStyle.js
import headerLinksStyle from 'styles/jss/nextjs-material-kit-pro/components/headerLinksStyle.js';
import { mlAuto, title, whiteColor, grayColor } from 'styles/jss/original/base';

const styles = (theme) => ({
  ...headerLinksStyle(theme),
  colorGray: {
    color: grayColor[1],
    '&:hover, &:focus': {
      color: grayColor[1]
    }
  },
  colorWhite: {
    color: whiteColor,
    '&:hover, &:focus': {
      color: whiteColor
    }
  },
  title,
  mlAuto,
  navButton: {
    width: '108px',
    padding: '12px 0',
    '@media (max-width: 768px)': {
      marginTop: '30px',
      width: '100%'
    }
  }
});

export default styles;
