import firebase from '@/lib/firebase/initFirebase';
import 'firebase/firestore';

export function getUserFirestore() {
  return firebase.firestore().collection('users');
}

export function getCommentFirestore() {
  return firebase.firestore().collection('comments');
}
